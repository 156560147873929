<template>
  <section>
    <div class="userViewHelp">
      <div class="userViewHelp__content">
        <div :class="['userViewHelp__button', !view ? 'userViewHelp__button-active' : '']" @click="viewTicket(false)">Mis tickets</div>
        <div :class="['userViewHelp__button', view ? 'userViewHelp__button-active' : '']" @click="viewTicket(true)">Crear Ticket</div>
      </div>
      <div class="userViewHelp__view" v-if="view">
        <createcaseVue></createcaseVue>
      </div>
      <div v-else>
        <resumeCasesVue></resumeCasesVue>
      </div>
    </div>
  </section>
</template>

<script>
import createcaseVue from "./createcase.vue";
import resumeCasesVue from "./resumeCases.vue";
export default {
  components: {
    createcaseVue,
    resumeCasesVue,
  },
  name: "userMain",
  data() {
    return {
      view: false,
    };
  },
  methods: {
    viewTicket(view) {
      this.view = !!view;
    },
  },
  beforeMount() {},
};
</script>

<style lang="scss">
.userViewHelp {
  .helpCreate {
    margin-top: 27px;
    padding: 0px;
  }
  .resumeH {
    padding: 0px;
  }
  &__content {
    display: flex;
  }
  &__button {
    padding: 5px;
    height: 36px;
    width: 100%;
    cursor: pointer;
    border: #d8d8d8 solid 1px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 9px 9px 0px 0px;
    &-active {
      background-color: #bd0909;
      color: white;
    }
  }
}
</style>
